import { FC } from 'react';

import { Loader } from '@/components/UI';
import { PageWrapper } from '@/components/layouts/Containers';

import styles from './LoaderPage.module.scss';

const LoaderPage: FC = () => {
  const meta = {
  };

  return (
    <PageWrapper mainStyles={styles.main} meta={meta}>
      <Loader />
    </PageWrapper>
  );
};

export default LoaderPage;
