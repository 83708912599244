import { MainPage } from '@/reactPages/MainPage'
import NextWrapper from '@/components/layouts/Containers/NextWrapper/NextWrapper';

export const meta = {
  title: 'Недвижимость на Сахалине',
  description:
    'Сахалинское ипотечное агентство - это сервис, помогающай найти лучшее предложение на рынке недвижимости Сахалинской области.',
  keywords: 'Сахалинское ипотечное агентство, Сахалинская область, купить, арендовать, продать',
};
const Home = () => {
  return (
    <>
      <NextWrapper meta={meta}></NextWrapper>
      <MainPage></MainPage>
    </>
  );
};

export default Home;
